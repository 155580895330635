import React from "react"
import { navigate } from "@gatsbyjs/reach-router"

import Layout from "../components/Layout"

export default function ErrorPage() {
  return (
    <Layout
      noHero
      content={
        <section className="bg-gray-200 flex flex-col justify-center items-center w-full h-[50vh] px-4 text-center">
          <h2 className="text-4xl mb-4">404 Error</h2>
          <p className="mb-3">
            This page was not found. Either this page is still under
            construction or the link is incorrect.
          </p>
          <button
            className="underline hover:no-underline"
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
        </section>
      }
    />
  )
}
